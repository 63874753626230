import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/index.css';
import '../styles/space.scss';

import { CognitoIdentityClient } from '@aws-sdk/client-cognito-identity';
import { fromCognitoIdentityPool } from '@aws-sdk/credential-provider-cognito-identity';
import { S3Client, ListObjectsCommand } from '@aws-sdk/client-s3';

import Gallery from 'react-grid-gallery';

const s3 = new S3Client({
  region: 'us-west-2',
  credentials: fromCognitoIdentityPool({
    client: new CognitoIdentityClient({ region: 'us-west-2' }),
    identityPoolId: "us-west-2:593fc590-157b-45bb-9bbb-e83c90c49e4d",
  }),
});

const IndexPage = () => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const getImages = async () => {
      const data = await s3.send(
        new ListObjectsCommand({
          Prefix: 'indexcards/',
          Bucket: 'opbgallery',
        })
      );
      const images = data.Contents.filter((img) => (img.Key.endsWith('png')));
      setImages(images.map((img) => ({
        src: `https://s3.us-west-2.amazonaws.com/opbgallery/${encodeURIComponent(img.Key)}`,
        thumbnail: `https://s3.us-west-2.amazonaws.com/opbgallery/${encodeURIComponent(img.Key)}`,
        thumbnailWidth: 500,
        thumbnailHeight: 300,
      })));
    };
    getImages();
  }, []);

  return (
    <main>
      <Helmet>
        <title>Outer Planet Brewing Community Artwork Wall</title>
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-YW66CS7BX4"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-YW66CS7BX4');
          `}
        </script>
      </Helmet>
      <div className="spaceBackground">
        <div className="stars" />
        <div className="twinkling" />
        <div className="clouds" />
      </div>
      <div style={{ zIndex: 1000 }}>
        <div className="header">
          <h1>To our wonderful patrons</h1>
        </div>
        <div className="introText">
          <p>As Outer Planet Brewing celebrates our 6th anniversary, we wanted to take a moment to say thank you, so much, to everyone who has supported us over these past six amazing years. It's truly a pleasure to be able to share our passion for our craft with the Capitol Hill community.</p>
          <p>It's been nearly a year since, like so many others, we had to temporarily close our taproom and figure out more creative solutions for getting beer into everyone's hands. And while we miss having you all in our taproom, we're humbled by and grateful for your continued support and hopeful about the future as we endure these hardships together.</p>
          <p>We're using some of this downtime to give our taproom a fresh coat of paint. We had to relocate the incredible patron-drawn artwork that has adorned our taproom pillars these past couple of years and took the opportunity to digitize them. Thank you for the memories and the laughs. We're looking forward to having you all back inside soon!</p>
          <p>Cheers,</p>
          <p>Jeff, Gabriel, and all of us here at Outer Planet</p>
        </div>
        <div className="gallery">
          <Gallery
            images={images}
          />
        </div>
      </div>
    </main>
  );
};

export default IndexPage;
